.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.tab {
  @apply flex flex-col lg:flex-row text-sm lg:text-base lg:font-semibold justify-center items-center gap-0 lg:gap-2 cursor-pointer w-full lg:w-fit p-2 lg:py-3 md:px-4 xl:px-8;

  img {
    filter: brightness(0) invert(1);
    @apply w-4;
  }
}

.tab-active {
  color: black;
  @apply bg-white rounded-2xl;

  .icon {
    filter: brightness(0) saturate(100%);
  }

  .filter-custom-color img {
    filter: brightness(0) saturate(100%);
  }

  .filter-custom-color:hover img {
    filter: invert(31%) sepia(96%) saturate(659%) hue-rotate(232deg)
      brightness(98%) contrast(93%);
  }
}

.button-status-red {
  @apply max-w-[120px] px-2 lg:px-[22px] py-3 bg-red-500 rounded-[151.75px] justify-center items-center gap-2 flex w-full;
  foo: bar;
}

.button-status-yellow {
  @apply max-w-[120px] px-2 lg:px-[22px] py-3 bg-yellow-500 rounded-[151.75px] justify-center items-center gap-2 flex w-full;
  foo: bar;
}

.button-status-green {
  @apply max-w-[120px] px-2 lg:px-[22px] py-3 bg-green-500 rounded-[151.75px] justify-center items-center gap-2 flex w-full;
  foo: bar;
}

.filter-custom-red {
  filter: grayscale(100%) invert(30%) sepia(100%) saturate(10000%)
    hue-rotate(0deg) brightness(100%);
}

.filter-custom-black {
  filter: brightness(0) saturate(100%)
}

.filter-custom-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(312deg) brightness(101%) contrast(101%);
}
