@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;600;700;800;900&family=Orbitron&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: DigitalNumbers;
  src: url("/assets/fonts/DigitalNumbers-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  background: black;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker-wrapper {
  width: 100%;
}

.background-gradient {
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  background-image: radial-gradient(49% 81% at 30% 40%, #000 0%, #073aff00 100%),
    radial-gradient(113% 91% at 17% -2%, #000 1%, #ff000000 99%),
    radial-gradient(142% 91% at 83% 7%, #31005a 1%, #ff000000 99%),
    radial-gradient(142% 91% at -6% 74%, #3300b1 1%, #ff000000 99%),
    radial-gradient(142% 91% at 111% 84%, #310042 0%, #310042 100%);
}

.expand-tree details summary {
  list-style-type: " ";
}

.expand-tree details[open] summary::marker {
  vertical-align: baseline;
  width: 20px;
  height: 20px;
}

details {
  user-select: none;
}

details > summary img.icon {
  width: 42px;
  height: 42px;
  transition: all 0.3s;
  margin-left: 5px;
  transform: rotate(180deg);
}

details[open] summary img.icon {
  transform: rotate(0);
}

summary {
  display: flex;
  cursor: pointer;
}

summary::-webkit-details-marker {
  display: none;
}

.signup-btn {
  @apply hidden lg:flex w-full mx-auto lg:mx-0 text-center lg:justify-center max-w-[323px] lg:max-w-[445px] border-purple border rounded-3xl py-3 text-white font-semibold mt-16 text-lg lg:px-6 cursor-pointer hover:shadow-[0_0_20px_0_rgba(108,40,255,1)];
  foo: bar;
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}