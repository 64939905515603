.purple-filter {
  filter: brightness(0) saturate(100%) invert(19%) sepia(95%) saturate(7476%)
    hue-rotate(262deg) brightness(103%) contrast(100%);
}
.menu-icon {
  @apply w-6 h-6 cursor-pointer;
}

.active-icon {
  @apply opacity-50;
}

.menu-icon:hover {
  filter: invert(37%) sepia(100%) saturate(7467%) hue-rotate(258deg)
    brightness(103%) contrast(105%);
}